<template>
  <div>
      <metier-guide-vue  :showbutton="true">

      </metier-guide-vue>
  </div>
</template>

<script>
import MetierGuideVue from '../../outils/guide/MetierGuide.vue'


export default {
  components: {
    MetierGuideVue,
  },
  directives: {

  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
